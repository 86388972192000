<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <button
        style="min-width: 1rem"
        class="btn btn-decrement btn-pink"
        type="button"
        :disabled="disabled"
        @click="decrement"
      >
        <strong>-</strong>
      </button>
    </div>

    <input
      ref="input"
      type="text"
      style="text-align: center"
      class="form-control qtySelector"
      placeholder
      :value="value"
      :disabled="disabled"
      @change="onChange"
    >

    <div class="input-group-append">
      <button
        style="min-width: 1rem"
        class="btn btn-increment btn-pink"
        type="button"
        :disabled="disabled"
        @click="increment"
      >
        <strong>+</strong>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QtySelector',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [Number, String],
      default: 0,
    },

    min: {
      type: [Number, String],
      default: 1,
    },

    max: {
      type: [Number, String],
      default: 1000,
    },
  },

  methods: {
    clamp (value) {
      value = Number(value)
      return Math.min(Math.max(value, this.min), this.max).toString()
    },

    onEmit (event, value) {
      this.$emit(event, this.clamp(value))
    },

    onChange ($event) {
      this.onEmit('change', $event.target.value)
      this.onInput($event)
      this.$refs.input.value = this.clamp($event.target.value)
    },

    onInput ($event) {
      this.onEmit('input', $event.target.value)
    },

    increment () {
      const value = Number(this.value) + 1
      return this.onEmit('input', value)
    },

    decrement () {
      const value = Number(this.value) - 1
      return this.onEmit('input', value)
    },
  },
}
</script>
