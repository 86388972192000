<template>
  <div class="col text-center">
    <h1><i class="fal fa-circle-notch fa-spin" /> Laster...</h1>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
}
</script>
