import $ from 'jquery'

var config = {
  decrementButton: '<strong>-</strong>', // button text
  incrementButton: '<strong>+</strong>', // ..
  groupClass: '', // css class of the resulting input-group
  buttonsClass: 'btn-pink',
  buttonsWidth: '1rem',
  textAlign: 'center',
  autoDelay: 500, // ms holding before auto value change
  autoInterval: 100, // speed of auto value change
  boostThreshold: 10, // boost after these steps
  boostMultiplier: 'auto', // you can also set a constant number as multiplier
  locale: 'nb-NO', // the locale for number rendering; if null, the browsers language is used
}

$('.qtySelector').inputSpinner(config)
