import $ from 'jquery'

/**
 * Move focus to search input when expanding search
 */
$(document).ready(function () {
  $('.navbar-toggler').click(function (event) {
    event.preventDefault()
    $(this)
      .children()
      .toggleClass('fa-bars fa-times')
    $('html').toggleClass('menu-open')
    $(this)
      .next()
      .fadeToggle()

    if ($('html').hasClass('menu-open')) {
      $('#map').fadeOut(500)
    } else {
      $('#map').show()
    }
  })
})
