<template>
  <div class="whiteBox">
    <h3>Subtotal ink. MVA</h3>

    <template v-if="!disabled">
      <h2>
        <span>{{ total }}</span>
      </h2>
    </template>
    <template v-else>
      <h2><i class="fal fa-circle-notch fa-spin" /></h2>
    </template>

    <p>
      <strong>Subtotal:</strong> {{ cost }} <br>
      <strong>MVA:</strong> {{ tax }}
    </p>

    <a
      v-show="hasCourses && !useGiftCard"
      href="#"
      class="btn btn-white"
      @click="useGiftCard = true"
    >
      <i class="far fa-gift-card" />
      Bruk gavekort
    </a>

    <div
      v-if="useGiftCard"
      class="alert alert-secondary"
    >
      <p class="text-danger">
        <i class="far fa-info-circle" /> Gavekortet må leveres ved oppmøte på kurs/omvisning. Dersom man glemmer
        gavekortet må kurset betales ved opppmøte, og man får refundert beløpet når gavekortet leveres.
      </p>

      <div class="form-group">
        <label>Beløp</label>

        <input
          v-model="giftcard.amount"
          type="number"
          class="form-control"
          value="0"
          required
        >
      </div>

      <div class="form-group">
        <label>Referanse/gavekortnummer</label>

        <input
          v-model="giftcard.reference"
          type="text"
          class="form-control"
          required
        >
      </div>

      <button
        class="btn btn-pink"
        :disabled="submitting"
        @click="registerGiftCard"
      >
        Registrer
      </button>

      <button
        class="btn btn-link float-right"
        @click="useGiftCard = false"
      >
        <i class="fas fa-times" />
        Avbryt
      </button>
    </div>

    <a
      v-show="!useGiftCard"
      href="/utsjekk/"
      class="btn btn-pink btn-lg"
      :class="{ disabled }"
      @click="completeOrder"
    >
      Fullfør din bestilling
    </a>
  </div>
</template>

<script>
import Axios from 'axios'

export default {
  name: 'CartInfo',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    total: {
      type: String,
      default: '0',
    },

    cost: {
      type: String,
      default: '0',
    },

    tax: {
      type: String,
      default: '0',
    },

    hasCourses: {
      type: Boolean,
      default: false,
    },

    onCompleteOrder: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    useGiftCard: false,

    submitting: false,

    giftcard: {
      reference: '',
      amount: 0,
    },
  }),

  methods: {
    async registerGiftCard () {
      this.submitting = true
      try {
        await Axios.post('/api/cart/useGiftCard', this.giftcard)
        this.useGiftCard = false
        this.submitting = false
        this.giftcard.amount = 0
        this.giftcard.reference = ''
        this.$emit('register-giftcard')
      } catch (e) {
        console.error(e)
      } finally {
        this.useGiftCard = false
        this.submitting = false
      }
    },

    completeOrder (event) {
      // eslint-disable-next-line
      this.onCompleteOrder?.(event)
    },
  },
}
</script>
