<template>
  <div class="cart">
    <template v-if="order">
      <template v-if="items.length">
        <transition-group
          name="list"
          tag="div"
          class="products"
        >
          <CartProduct
            v-for="item in items"
            :key="item.id"
            ref="productRefs"
            :item="item"
            :disabled="isBusy"
            @qty-change="onQtyChange"
            @text-content-change="onTextContentChange"
            @delete="onDelete"
          />
        </transition-group>

        <div class="checkout">
          <CartInfo
            :total="total"
            :cost="cost"
            :tax="tax"
            :has-courses="hasCourses"
            :disabled="isBusy"
            :on-complete-order="onCompleteOrder"
            @register-giftcard="getOrder"
          />

          <CartAddPersonalGreeting
            v-if="!orderHasPersonalGreeting"
            @add-personal-greeting="onAddPersonalGreeting"
          />
        </div>
      </template>

      <div
        v-else
        class="alert alert-info"
      >
        Du har ingen produkter i handlekurven
      </div>
    </template>
    <template v-else>
      <LoadingIndicator />
    </template>
  </div>
</template>

<script>
import Axios from 'axios'

import CartInfo from './CartInfo'
import CartProduct from './CartProduct'
import LoadingIndicator from './LoadingIndicator'
import CartAddPersonalGreeting from './CartAddPersonalGreeting'

export default {
  name: 'ShoppingCart',

  components: {
    CartAddPersonalGreeting,
    CartInfo,
    CartProduct,
    LoadingIndicator,
  },

  data: () => ({
    order: null,
    isBusy: false,
    productRefs: [],
  }),

  computed: {
    total () {
      return this.order.total ?? '0'
    },
    cost () {
      return this.order.cost ?? '0'
    },
    tax () {
      return this.order.tax ?? '0'
    },
    items () {
      return this.order.items ?? []
    },
    hasCourses () {
      return this.order.hasCourses ?? false
    },
    orderHasPersonalGreeting () {
      return this.order.items.some((orderItem) => (
        orderItem.entry_id === '22613'
      ))
    },
    invalidProductRefs () {
      return this.productRefs.filter((product) => (
        product.textContentInvalid
      ))
    },
    hasInvalidProductRefs () {
      return this.invalidProductRefs.length > 0
    },
  },

  mounted () {
    this.getOrder()
  },

  updated () {
    this.productRefs = this.$refs.productRefs
  },

  methods: {
    async onQtyChange (payload) {
      if (!this.isBusy) {
        this.isBusy = true
        try {
          const quantityDelta = payload.new_qty - payload.old_qty
          const item = this.items.find((item) => (item.id === payload.item_id))

          await Axios.post('/api/cart/update/', payload)

          if (quantityDelta !== 0) {
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
              event: quantityDelta > 0
                ? 'add_to_cart'
                : 'remove_from_cart',
              ecommerce: {
                currency: 'NOK',
                value: item.price * Math.abs(quantityDelta),
                items: [{
                  item_id: item.id,
                  item_name: item.name,
                  price: item.price,
                  quantity: Math.abs(quantityDelta),
                }],
              },
            })
          }

          await this.getOrder()
        } catch (error) {
          console.error(error)
        } finally {
          this.isBusy = false
        }
      }
    },

    async onTextContentChange (payload) {
      if (!this.isBusy) {
        this.isBusy = true
        try {
          await Axios.post('/api/cart/updateTextContent', payload)
          await this.getOrder()
        } catch (error) {
          console.error(error)
        } finally {
          this.isBusy = false
        }
      }
    },

    async onDelete (payload) {
      if (!this.isBusy) {
        this.isBusy = true
        try {
          const item = this.items.find((item) => (item.id === payload.item_id))

          await Axios.post('/api/cart/delete/', payload)

          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
              currency: 'NOK',
              value: parseFloat(item.variant_cost) * parseInt(item.no_of_entries),
              items: [{
                item_id: item.entry_id,
                item_name: item.entry_name,
                price: parseFloat(item.variant_cost),
                quantity: parseInt(item.no_of_entries),
              }],
            },
          })

          await this.getOrder()
        } catch (error) {
          console.error(error)
        } finally {
          this.isBusy = false
        }
      }
    },

    async getOrder () {
      this.isBusy = true
      try {
        const response = await Axios.get('/api/cart')
        this.order = response.data
      } catch (_) {
        this.order = null
      } finally {
        this.isBusy = false
        if (this.order) {
          const cartIndicator = document.getElementById('cartCountIndicator')
          if (cartIndicator) {
            if (Number(this.order.count)) {
              cartIndicator.dataset.cartItemCount = this.order.count
            } else {
              delete cartIndicator.dataset.cartItemCount
            }
          }
        }
      }
    },

    async onAddPersonalGreeting () {
      if (!this.isBusy) {
        this.isBusy = true
        try {
          await Axios.post('/api/cart/add/', {
            entry_id: 15985,
            variant_id: 22613,
            qty: 1,
          })
          await this.getOrder()
        } catch (error) {
          console.error(error)
        } finally {
          this.isBusy = false
        }
      }
    },

    onCompleteOrder (event) {
      if (this.hasInvalidProductRefs) {
        event.preventDefault()

        this.invalidProductRefs[0].focus()
      }
    },
  },
}
</script>
