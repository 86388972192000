import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faBars as fasBars,
  faTimes as fasTimes,
  faMagnifyingGlass as fasMagnifyingGlass,
  faCartShopping as fasCartShopping,
  faUserLock as fasUserLock,
  faCalendar as fasCalendar,
  faClock as fasClock,
  faRing as fasRing,
  faBed as fasBed,
  faPhone as fasPhone,
  faCalendarCheck as fasCalendarCheck,
  faHatChef as fasHatChef,
  faStar as fasStar,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faTrashCan as fasTrashCan,
  faCalendarDays as fasCalendarDays,
  faBagsShopping as fasBagsShopping,
  faPencil as fasPencil,
  faFileArrowDown as fasFileArrowDown,
  faCheck as fasCheck,
  faHouse as fasHouse,
  faArrowRightFromBracket as fasArrowRightFromBracket,
  faEnvelope as fasEnvelope,
  faTriangleExclamation as fasTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faChevronRight as falChevronRight,
  faChevronLeft as falChevronLeft,
  faCircleNotch as falCircleNotch,
} from '@fortawesome/pro-light-svg-icons'
import {
  faSpinner as farSpinner,
  faInfoCircle as farInfoCircle,
  faExternalLink as farExternalLink,
  faClock as farClock,
  faSignature as farSignature,
  faHeart as farHeart,
  faSnowflake as farSnowflake,
  faPiggyBank as farPiggyBank,
  faPlus as farPlus,
  faImage as farImage,
  faLink as farLink,
  faGiftCard as farGiftCard,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faStore as fadStore,
  faBoxOpen as fadBoxOpen,
  faTruck as fadTruck,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faFacebook as fabFacebook,
  faTwitter as fabTwitter,
  faInstagram as fabInstagram,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  fasBars,
  fasTimes,
  falChevronRight,
  falChevronLeft,
  falCircleNotch,
  fabFacebook,
  fabTwitter,
  fabInstagram,
  fasCalendar,
  fasClock,
  fasRing,
  fasBed,
  fasPhone,
  fasCalendarCheck,
  fasMagnifyingGlass,
  fasCartShopping,
  fasUserLock,
  fasHatChef,
  fasStar,
  fasCheck,
  fasTrashCan,
  fasChevronLeft,
  fasChevronRight,
  fasCalendarDays,
  fasBagsShopping,
  fasPencil,
  fasFileArrowDown,
  fasHouse,
  fasArrowRightFromBracket,
  fasEnvelope,
  fasTriangleExclamation,
  farSpinner,
  farInfoCircle,
  farExternalLink,
  farClock,
  farSignature,
  farHeart,
  farSnowflake,
  farPiggyBank,
  farPlus,
  farImage,
  farPlus,
  farImage,
  farLink,
  fadStore,
  fadBoxOpen,
  farGiftCard,
  fadTruck,
)
dom.watch()
