<script>
export default {
  name: 'CartAddPersonalGreeting',
}
</script>

<template>
  <div class="whiteBox">
    <h5>Personlig hilsen</h5>

    <p>Om du ønsker å legge ved en personlig hilsen i bestillingen kan du gjøre dette.</p>

    <div class="productOptions">
      <input
        type="hidden"
        value="15985"
        name="entry_id"
      >

      <input
        type="hidden"
        value="22613"
        name="variant_id"
      >

      <button
        class="btn btn-pink"
        type="button"
        @click="$emit('add-personal-greeting')"
      >
        Legg til personlig hilsen
      </button>
    </div>
  </div>
</template>

<style lang="scss">
</style>
