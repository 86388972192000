<template>
  <div>
    <div
      class="cartProduct whiteBox"
      :class="cartProductClassList"
    >
      <template v-if="!addOnProduct || item.properties.extraType === 'product'">
        <div
          class="productImage"
          v-html="item.product.image"
        />
      </template>
      <template v-else>
        <div class="productAddOnIcon">
          <i
            :class="addOnProductIcon"
            aria-hidden="true"
          />
        </div>
      </template>

      <div class="productMeta">
        <a :href="item.product.url">
          <h2>{{ item.entry_name }}</h2>
        </a>

        <p v-if="item.variant_name">
          {{ item.variant_name }}
        </p>

        <p v-if="isPersonalGreetingProduct">
          Gavehilsen kan ikke inneholde emoji-er.
        </p>

        <template v-if="hasTextField">
          <input
            v-if="item.properties.textFieldType === 'text'"
            ref="textContent"
            v-model.lazy="textContent"
            type="text"
            class="form-control cart-editTextField"
            :disabled="disabled"
          >

          <textarea
            v-else
            ref="textContent"
            v-model.lazy="textContent"
            class="form-control cart-editTextField text-center"
            :disabled="disabled"
          />

          <span
            v-if="textContentInvalid"
            class="text-danger"
          >
            <span v-if="hasTooLongLine">
              Hver linje kan ha maks 35 bokstaver/tall
            </span>

            <span v-else-if="hasTooManyLines">
              Kan ikke inneholde tekst på mer enn 8 linjer
            </span>

            <span v-else-if="missingGreeting">
              Gavehilsen må fylles ut
            </span>
          </span>
        </template>

        <p v-if="item.properties.extraType === 'imageUpload'">
          <a
            :href="item.properties.filePath"
            target="_blank"
          >
            <i class="far fa-link" /> Se bilde (åpner i nytt vindu)
          </a>
        </p>

        <div
          v-if="item.variant_cost"
          class="productPrice"
        >
          {{ item.variant_cost }}
          <small
            v-if="item.no_of_entries > 1"
            class="text-muted"
          >
            (Totalt kr. {{ item.entries_total }})
          </small>
        </div>
      </div>

      <div class="removeProduct">
        <QtySelector
          v-if="showQtySelector"
          v-model="quantity"
          @input="onQuantityChange"
          :min="item.properties.minimumQty ? Number(item.properties.minimumQty) : 1"
          :disabled="disabled"
        />

        <a
          v-if="item.properties.extraType !== 'cakeStandDeposit'"
          href="#"
          class="remove removeCartItem"
          :class="{ disabled }"
          @click="onDelete"
        >
          <span class="sr-only">
            Fjern
          </span>

          <i class="fas fa-trash-can" />
        </a>
      </div>
    </div>
    <div
      v-if="item.linkedItems && item.linkedItems.length"
      class="addOnList"
    >
      <p class="addOnLabel">
        Tillegg
      </p>

      <transition-group
        name="list"
        tag="div"
      >
        <CartProduct
          v-for="linkedItem in item.linkedItems"
          :key="linkedItem.id"
          :item="linkedItem"
          add-on-product
          no-margin
          :disabled="disabled"
          v-on="$listeners"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import QtySelector from './QtySelector'

export default {
  name: 'CartProduct',

  components: {
    QtySelector,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: null,
    },

    noMargin: {
      type: Boolean,
      default: false,
    },

    addOnProduct: {
      type: Boolean,
      default: false,
    },
  },

  data: (vm) => ({
    intermediaryTextContent: '',
    textContentInvalid: false,
    hasTooLongLine: false,
    hasTooManyLines: false,
    missingGreeting: false,
    quantity: vm.item.no_of_entries,
  }),

  computed: {
    isPersonalGreetingProduct () {
      return this.item.entry_id === '22613'
    },

    showQtySelector () {
      return !this.giftcard &&
        (!this.addOnProduct || parseInt(this.item.properties.qtyChangeable))
    },

    hasTextField () {
      return this.item.properties.extraType === 'textField' || this.isPersonalGreetingProduct
    },

    addOnProductIcon () {
      if (this.addOnProduct) {
        if (this.item.entry_name === 'Sjokoladehjerte') {
          return ['far', 'fa-heart']
        }

        switch (this.item.properties.extraType) {
          case 'frozen':
            return ['far', 'fa-snowflake']
          case 'imageUpload':
            return ['far', 'fa-image']
          case 'textField':
            return ['far', 'fa-signature']
          case 'cakeStandDeposit':
            return ['far', 'fa-piggy-bank']
          default:
            return ['far', 'fa-plus']
        }
      }

      return []
    },

    giftcard () {
      if (this.item.properties.giftCardUsage) {
        return true
      }
      return false
    },

    cartProductClassList: ({ addOnProduct, noMargin, item }) => ({
      addOnProduct,
      noMargin,
      hasExtras: item.linkedItems && item.linkedItems.length,
    }),

    textContent: {
      get () {
        return this.intermediaryTextContent
      },

      set (value) {
        this.intermediaryTextContent = value

        if (this.isPersonalGreetingProduct) {
          const numberOfNewLines = value.match(/\n/g) ? value.match(/\n/g).length : 0
          this.textContentInvalid = false
          this.hasTooLongLine = false
          this.hasTooManyLines = false
          this.missingGreeting = false

          for (const line of value.split(/\n/g)) {
            if (line.length > 35) {
              this.hasTooLongLine = true
            }
          }

          if (this.hasTooLongLine) {
            if (this.hasTooLongLine) {
              this.textContentInvalid = true
            } else {
            }
          } else if (numberOfNewLines >= 8) {
            this.textContentInvalid = true
            this.hasTooManyLines = true
          } else if (!value) {
            this.textContentInvalid = true
            this.missingGreeting = true
          } else {
            this.item.properties.textContent = value
          }
        } else {
          this.item.properties.textContent = value
        }
      },
    },
  },

  watch: {
    'item.no_of_entries': function (quantity) {
      this.quantity = quantity
    },

    'item.properties.textContent': [
      function (newTextContent) {
        if (!this.disabled) {
          this.$emit('text-content-change', {
            item_id: this.item.id,
            new_textContent: newTextContent,
          })
        }
      },
      {
        handler (newTextContentValue) {
          this.intermediaryTextContent = newTextContentValue
        },
        immediate: true,
      },
    ],
  },

  created () {
    // Triggers textContent setter to validate content from start.
    if (this.isPersonalGreetingProduct) {
      this.textContent = this.item.properties.textContent
    }
  },

  methods: {
    onDelete () {
      if (!this.disabled) {
        this.$emit('delete', { item_id: this.item.id })
      }
    },

    onQuantityChange (newQty) {
      if (!this.disabled) {
        this.$emit('qty-change', {
          item_id: this.item.id,
          new_qty: Number(newQty),
          old_qty: Number(this.item.no_of_entries),
        })
      }
    },

    focus () {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.textContent?.focus()
    },
  },
}
</script>
