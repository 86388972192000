import $ from 'jquery'
import Vue from 'vue'

import ShoppingCart from './components/ShoppingCart.vue'

Vue.component('shopping-cart', ShoppingCart)

const root = document.getElementById('cart')
if (root) {
// eslint-disable-next-line
new Vue({ el: root })
}

$(document).ready(function () {
  loadCart()
  $('#cartForm').on('submit', function (event) {
    event.preventDefault()
    var currentSubmitHtml = $('#addToCartButton').html()
    $('#addToCartButton').addClass('disabled').html('<i class="fal fa-spin fa-circle-notch"></i> Vennligst vent')
    var formData = new FormData(this)
    $.ajax({
      url: '/api/cart/add/',
      type: 'POST',
      data: formData,
      success (data) {
        updateCartIndicator()
        $('#addToCartButton').removeClass('disabled').html(currentSubmitHtml)
        $('#qty').val(1)

        if ('ga4Event' in (data.data ?? {})) {
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push(data.data.ga4Event)
        }
      },
      error (error) {
        const response = error.responseJSON

        if (response?.error && response?.message === 'dateAvailabilityConflict') {
          const dateAvailabilityConflictMessageId = 'dateAvailabilityConflictMessage'

          const url = new URL(window.location)
          url.hash = dateAvailabilityConflictMessageId
          url.searchParams.set('forceReload', url.searchParams.get('forceReload') === '1' ? '2' : '1')

          window.location.assign(url)
        }
      },
      cache: false,
      contentType: false,
      processData: false,
    })
  })

  function updateCartIndicator (count = null) {
    if (!count) {
      $.get('/api/cart/getCount/', function (response) {
        if (Number(response)) {
          $('#cartCountIndicator').attr('data-cart-item-count', response)
        }
      })
    } else {
      $('#cartCountIndicator').attr('data-cart-item-count', count)
    }
  }

  window.updateCartIndicator = updateCartIndicator

  function updateItemPrice (item) {
    $('#productPriceValue').html('<i class="fal fa-spin fa-circle-notch"></i>')
    var formData = $(item).closest('form').serialize()
    var formType = $(item).closest('form').attr('data-type')
    if (formType === 'product') {
      $.post('/api/product/calculatePrice/', formData, function (response) {
        $('#productPriceValue').html(response.totalPrice)
        $('#productExtrasTotal').html(response.totalLabel)
      })
    } else if (formType === 'course') {
      $.post('/api/course/calculatePrice/', formData, function (response) {
        $('#productPriceValue').html(response.totalPrice)
        $('#productExtrasTotal').html(response.totalLabel)
      })
    }
  }

  function loadCart () {
    $.get('/api/cart/get/', function (response) {
      $('#cartArea').html(response)
    })
  }

  $('.variantRadio').on('change', function () {
    updateItemPrice($(this))
  })

  $('#qty').on('change', function () {
    updateItemPrice($(this))
  })

  $('.extraSelect').on('change', function () {
    updateItemPrice($(this))
    if (this.checked) {
      if ($(this).attr('data-minQty')) {
        $('#qty').val($(this).attr('data-minQty')).attr('min', $(this).attr('data-minQty'))
      }
      $(this).closest('.form-check').addClass('selected').find('.hiddenValues').slideDown()
      $(this).closest('.form-check').find(".hiddenValues .form-control[name$='[textContent]']").attr('required', true)
    } else {
      $(this).closest('.form-check').removeClass('selected').find('.hiddenValues').hide()
      $(this).closest('.form-check').find(".hiddenValues .form-control[name$='[textContent]']").attr('required', false)
    }
  })

  $('.productExtras .qtySelector').on('change', function (event) {
    updateItemPrice($(this))
  })

  $('[data-toggle="popover"]').popover()
})
